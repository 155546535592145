import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Modal } from '../../dist/js/bolt';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <p>{`O componente modal aplica funcionalidades de entrada e saída a um determinado elemento. Após o conteúdo ser construído, deve-se enviar para o contrutor o HTML
desse conteúdo.`}</p>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// construtor, deve receber o HTML do conteúdo do modal
export interface IModalConstructor {
  new (modalContent: HTMLElement): IModal;
}

export interface IModal {
  isOpen: boolean; // get/ set - define e informa se o modal está aberto ou fechado

  // onClose: função a ser executada quando ocorre uma intenção de fechar o modal,
  // clicando na parte escura. o modal não fecha de forma automatizada, é necessário
  // implementar essa função utilizando a propriedade isOpen para fechar o modal
  onClose?: () => void;

  onStartOpen?: () => void; // função a ser executada no inicio da animação de abertura
  onEndOpen?: () => void; // função a ser executada quando a animação de abertura termina ou é interrompida
  onStartClose?: () => void; // função a ser executada no início da animação de saída
  onEndClose?: () => void; // função a ser executada quando a animação de saída termina ou é interrompida

  readonly destroy: () => void; // função que permite remover/ desmontar o componente do HTML
}
`}</code></pre>
    <h2 {...{
      "id": "exemplo"
    }}>{`Exemplo`}</h2>
    <br />
    <Playground __position={1} __code={'() => {\n  const [mod, setMod] = React.useState(null)\n  const ref = React.useRef()\n  const closebt = React.useRef()\n  React.useEffect(() => {\n    if (ref.current && !mod) {\n      setMod(new Modal(ref.current))\n    }\n    if (mod && closebt.current) {\n      mod.onClose = closeModal\n      closebt.current.addEventListener(\'click\', closeModal)\n    }\n    return () => {\n      if (mod) {\n        mod.destroy()\n      }\n    }\n  }, [ref.current, closebt.current, mod])\n  const openModal = () => {\n    if (mod) {\n      mod.isOpen = true\n    }\n  }\n  const closeModal = () => {\n    if (mod) {\n      mod.isOpen = false\n    }\n  }\n  return (\n    <>\n      <div className=\"tw-flex\">\n        <button className=\"tw-btn tw-btn-secondary\" onClick={openModal}>\n          abrir modal\n        </button>\n      </div>\n      <div ref={ref} className=\"tw-flex tw-flex-col\">\n        teste\n        <button ref={closebt} className=\"tw-btn tw-btn-primary tw-mt-4\">\n          fechar\n        </button>\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Modal,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [mod, setMod] = React.useState(null);
        const ref = React.useRef();
        const closebt = React.useRef();
        React.useEffect(() => {
          if (ref.current && !mod) {
            setMod(new Modal(ref.current));
          }

          if (mod && closebt.current) {
            mod.onClose = closeModal;
            closebt.current.addEventListener('click', closeModal);
          }

          return () => {
            if (mod) {
              mod.destroy();
            }
          };
        }, [ref.current, closebt.current, mod]);

        const openModal = () => {
          if (mod) {
            mod.isOpen = true;
          }
        };

        const closeModal = () => {
          if (mod) {
            mod.isOpen = false;
          }
        };

        return <>
        <div className="tw-flex">
          <button className="tw-btn tw-btn-secondary" onClick={openModal}>
            abrir modal
          </button>
        </div>
        <div ref={ref} className="tw-flex tw-flex-col">
          teste
          <button ref={closebt} className="tw-btn tw-btn-primary tw-mt-4">
            fechar
          </button>
        </div>
      </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      